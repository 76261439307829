import parseUrlRegion from './parseUrlRegion';

/**
 * Return a formatted link
 *
 * @param {(?object|?string)} link the link object or string
 * @param {boolean} asObject should the function be returning an object
 * @param {?object} bind bind the attributes to the object
 * @param {string} bind.linkName link name returned on the object
 * @param {string} bind.targetName target name returned on the object
 * @return {(object|string)} the parsed link or an object with {link,target}
 */
export default function parseLinkHref(link, asObject = false, bind) {
  const defaultValues = { linkName: 'link', targetName: 'target', ...bind };

  if (!link || typeof link === 'string') {
    return formattedResponse({}, link, asObject, defaultValues);
  }

  let url = link.cached_url;

  if (link.cached_url === '') {
    url = '';
  }

  if (link.linktype === 'story') {
    url = parseUrlRegion(link.cached_url);
  }

  url = removeBackSlash(url);

  url = getAnchorLink(link, url);

  return formattedResponse(link, url, asObject, defaultValues);
}

function removeBackSlash(url) {
  if (url.endsWith('/')) {
    return url.replace(/\/+$/, '');
  }
  return url;
}

function formattedResponse(linkObject, url, asObject, bind) {
  if (asObject) {
    return {
      [bind.linkName]: url,
      [bind.targetName]: linkObject.hasOwnProperty('target')
        ? linkObject.target
        : null,
    };
  }
  return url;
}

function getAnchorLink(link, url) {
  if (link.hasOwnProperty('anchor') && link.anchor !== '') {
    return `${url}#${link.anchor}`;
  }
  return url;
}
