export default {
  data() {
    return {
      countries: require('./countries.json'),
      regions: require('./regions.json'),
      coreCountries: [
        'Australia',
        'New Zealand',
        'United States',
        'Canada',
        'United Kingdom',
        'Ireland',
        'Jersey',
        'Guernsey',
        'Isle of Man',
        'South Africa',
        'United Arab Emirates',
        'Netherlands',
        'Gibraltar',
        'Germany',
      ],
    };
  },
  methods: {
    /**
     * Returns the country object along with its region.
     *
     * @param {String} countryName the country name
     * @return {Object|null} the country object or null if not found
     */
    getCountryData(countryName) {
      const countryFound = this.countries.find(
        country => country.country === countryName
      );

      if (countryFound) {
        const countryRegion = this.regions.find(
          region => region.continent === countryFound.continent
        );
        return { ...countryFound, region: countryRegion?.region };
      }

      return null;
    },
    /**
     * Checks if the country is in the core list.
     *
     * @see: https://deputy.atlassian.net/browse/WOK-3311 (core list)
     *
     * @param {String} countryName the country name
     * @return {Boolean} whether the country is in the core list or not
     */
    isCoreCountry(countryName) {
      return this.coreCountries.includes(countryName);
    },
  },
};
