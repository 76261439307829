import Vue from 'vue';

const themes = {
  white: 'grey-10',
  yellow: 'yellow-10',
  peach: 'peach-10',
  azure: 'azure-60',
  pink: 'pink-50',
  purple: 'purple-50',
  enterprise: 'forest-50',
};

Vue.prototype.$gooseThemes = themes;

Vue.mixin({
  props: {
    theme: {
      type: String,
      validator: t => themes.hasOwnProperty(t.toLowerCase()),
      default: 'white',
    },
  },
  computed: {
    /**
     * This is the main color for a theme. It is typically used as the background color.
     * @return {String} name of the variable e.g. grey-10
     */
    themeColor() {
      return themes[this.theme.toLowerCase()];
    },
  },
});
