export const state = () => ({
  menuContent: {
    desktop: {},
    mobile: {},
  },
  menuHeader: 'default-header',
  footerClass: 'default-footer',
  headerText: '',
  headerOffset: 60,
  headerScrollPadding: 15,
});

export const getters = {
  isMenuHidden(state) {
    return state.menuHeader === 'header-none';
  },
  isFooterHidden(state) {
    return state.footerClass === 'footer-none';
  },
};

export const mutations = {
  // HEADER OFFSETS
  SET_HEADER_OFFSET(state, val) {
    state.headerOffset = val;
  },

  // NAVIGATION MENU
  SET_MENU_CONTENT(state, payload) {
    if (state.menuContent.hasOwnProperty(payload.breakpoint)) {
      state.menuContent[payload.breakpoint] = payload.content;
    }
  },
  SET_MENU_HEADER(state, menuHeader) {
    state.menuHeader = menuHeader;
  },
  SET_HEADER_TEXT(state, headerText) {
    state.headerText = headerText;
  },

  // FOOTER
  SET_FOOTER_CONTENT(state, payload) {
    state.footer = payload;
  },
  SET_FOOTER_CLASS(state, footerClass) {
    state.footerClass = footerClass;
  },
};

export const actions = {
  // HEADER OFFSET
  setHeaderOffset({ commit, state }) {
    // we only want specific elements in the header
    // we don't want opening/closing the nav menu to push the page around
    const headerElements = [
      ...document.getElementsByClassName('js-header-offset'),
    ];

    let headerOffset = 0;
    headerElements.forEach(el => (headerOffset += el.offsetHeight));

    // used to place absolute position elements e.g. g-tour-stations
    document.documentElement.style.setProperty(
      '--header-y-offset',
      headerOffset
    );

    // g-tour-stations are positioned after header (i.e. uses headerOffset)
    // but are position: sticky on mobile so we still need to include its height in scrolling to the page content
    const tourStationsOffset =
      document.getElementsByClassName('js-sticky-tour-stations')[0]
        ?.offsetHeight || 0;

    commit(
      'SET_HEADER_OFFSET',
      headerOffset + tourStationsOffset + state.headerScrollPadding
    );
  },

  // NAVIGATION MENU
  async fetchMenu({ commit, state }, { $storyapi, region, version }) {
    for (const breakpoint in state.menuContent) {
      if (state.menuContent.hasOwnProperty(breakpoint)) {
        const storyUrl = `cdn/stories/_content_types/menus/navigation/menu-${breakpoint}-${region}`;
        const storyParams = {
          version,
        };

        try {
          // const menu = await $storyapi.get(storyUrl, storyParams);
          const payload = {
            content: {}, // menuDesktopUs.story,
            breakpoint,
          };

          commit('SET_MENU_CONTENT', payload);
        } catch (e) {
          console.error('There was an error fetching the menu:', e);
          console.error('isPreview: ', process.env.isPreview);
          console.error('storyUrl: ', storyUrl);
          console.error('storyParams: ', storyParams);
        }
      }
    }
  },
  updateMenuHeader({ commit }, menuHeader) {
    commit('SET_MENU_HEADER', menuHeader);
  },
  updateHeaderText({ commit }, headerText) {
    commit('SET_HEADER_TEXT', headerText);
  },

  // FOOTER
  async fetchFooter({ commit }, { $storyapi, region, version }) {
    const storyUrl = `cdn/stories/_content_types/menus/footer/menu-footer-${region}`;
    const storyParams = {
      version,
    };

    try {
      // const footer = await $storyapi.get(storyUrl, storyParams);
      const payload = {}; // menuFooterUs.story;

      commit('SET_FOOTER_CONTENT', payload);
    } catch (e) {
      console.error('There was an error fetching the footer:', e);
      console.error('isPreview: ', process.env.isPreview);
      console.error('storyUrl: ', storyUrl);
      console.error('storyParams: ', storyParams);
    }
  },
  updateFooterClass({ commit }, footerClass) {
    commit('SET_FOOTER_CLASS', footerClass);
  },
};
